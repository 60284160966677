@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

@mixin d1 {
  @include text($fs-3, $extra-light, $ls-3, $lh-3);
}
@mixin d1-mobile {
  @include text($fs-3, $extra-light, $ls-3, $lh-3);
}

@mixin h1 {
  @include text($fs-1, $bold, $ls-2, $lh-1);
  padding-bottom: $size-1;
}
@mixin h1-mobile {
  @include text($fs-1-5, $semi-bold, $ls-1, $lh-84);
  padding-bottom: $size-2;
}

@mixin h2 {
  @include text($fs-2, $semi-bold, $ls-1, $lh-2);
  padding-bottom: $size-1;
}
@mixin h2-mobile {
  @include text($fs-3, $semi-bold, $ls-3, $lh-2);
  padding-bottom: $size-1;
}

@mixin h3 {
  @include text($fs-3, $semi-bold, $ls-2, $lh-2);
  padding-bottom: $size-1;
}
@mixin h3-mobile {
  @include text($fs-6, $semi-bold, $ls-3, $lh-3);
  padding-bottom: $size-1;
}

@mixin h4 {
  @include text($fs-4, $semi-bold, $ls-2, $lh-2);
}
@mixin h4-mobile {
  @include text($fs-8, $semi-bold, $ls-3, $lh-3);
}

@mixin h5 {
  @include text($fs-7, $semi-bold, $ls-3, $lh-4);
}
@mixin h5-mobile {
  @include text($fs-7, $semi-bold, $ls-3, $lh-4);
}

@mixin h6 {
  @include text($fs-8, $regular, $ls-4, $lh-5);
}
@mixin h6-mobile {
  @include text($fs-8, $regular, $ls-4, $lh-5);
}

@mixin p1 {
  @include text($fs-8, $bold, $ls-9, $lh-a);
}
@mixin p1-mobile {
  @include text($fs-11, $semi-bold, $ls-9, $lh-a);
}

@mixin p2 {
  @include text($fs-10, $regular, $ls-6, $lh-6);
}
@mixin p2-mobile {
  @include text($fs-11, $regular, $ls-6, $lh-6);
}

@mixin p3 {
  @include text($fs-11, $regular, $ls-7, $lh-5);
}
@mixin p3-mobile {
  @include text($fs-13, $regular, $ls-6, $lh-6);
}
@mixin p3-b3 {
  @include text($fs-12, $regular, $ls-7, $lh-5);
}
@mixin p3-b3-mobile {
  @include text($fs-13, $regular, $ls-7, $lh-5);
}
@mixin p3-b4 {
  @include text($fs-13, $regular, $ls-7, $lh-5);
}

@mixin caption-medium {
  @include text($fs-12, $bold, $ls-9, $lh-a);
}
@mixin caption-small {
  @include text($fs-13, $bold, $ls-9, $lh-a);
}

@mixin p5 {
  @include text($fs-7, $semi-bold, $ls-3, $lh-4);
}
@mixin p5-mobile {
  @include text($fs-11, $medium, $ls-5, $lh-a);
}

@mixin p6 {
  @include text($fs-8, $medium, $ls-4, $lh-a);
}
@mixin p6-mobile {
  @include text($fs-12, $light, $ls-5, $lh-a);
}

@mixin p7 {
  @include text($fs-11, $medium, $ls-5, $lh-a);
}
@mixin p7-mobile {
  @include text($fs-13, $regular, $ls-4, $lh-5-5);
}

@mixin p8 {
  @include text($fs-12, $light, $ls-5, $lh-a);
}
@mixin p8-mobile {
  @include text($fs-12, $light, $ls-5, $lh-a);
}
