@use "@scss/global.scss" as *;

.navbar {
  height: $navbar-height-desktop;

  .static {
    height: inherit;
  }
  .fixed {
    height: inherit;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 10;
    background: rgba($black-0, 0.5);

    backdrop-filter: blur(40px);

    .inner {
      height: inherit;
    }
  }

  .top-splash {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: -1;
  }

  @include mq-max($md) {
    height: $navbar-height-mobile;

    .fixed {
      padding-right: 8px !important;
    }
  }
}

.mobile-menu {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  top: 0px;
  left: 0px;
  z-index: 10;

  transform: translateY(-100%);

  background: rgba($black-0, 0.5);
  backdrop-filter: blur(40px);

  position: fixed;

  padding: 80px 16px;

  transition: transform 0.4s cubic-bezier(0.45, -0.01, 0.06, 1);

  &.show {
    transform: translateY(0%);
  }

  &.hide {
    transform: translateY(-100%);
  }
}
