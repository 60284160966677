@use "@scss/functions.scss" as *;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row($direction) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: $direction;
}

@mixin flex-column($v-direction, $h-direction: center) {
  display: flex;
  flex-direction: column;
  align-items: $v-direction;
  justify-content: $h-direction;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@mixin ellipsis-after-lines($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absolute-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin relative-center-x() {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@mixin relative-center-y() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@mixin relative-center() {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin text-gradient-left-right($color-left, $color-right) {
  background: text-gradient-left-right($color-left, $color-right);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-gradient-top-down($color-top, $color-down) {
  background: text-gradient-top-down($color-top, $color-down);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin linear-gradient-top-down($color-top, $color-down) {
  background: linear-gradient(180deg, $color-top -59.14%, $color-down 104.03%);
}

@mixin linear-gradient-left-right($color-left, $color-right) {
  background: linear-gradient(90deg, $color-left 0%, $color-right 0%);
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin blur($value) {
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -o-filter: blur($value);
  -ms-filter: blur($value);
  filter: blur($value);
}

@mixin user-select($user-select) {
  -webkit-user-select: $user-select;
  -moz-user-select: $user-select;
  -o-user-select: $user-select;
  -ms-user-select: $user-select;
  user-select: $user-select;
}

@mixin mq-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin text($fs, $fw, $ls, $lh) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: $ls;
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@function theme-var($key) {
  @if not map-has-key($theme-map-light, $key) {
    @error "key: '#{$key}', is not a key in map: #{$theme-map-light}";
  }
  @return var($key);
}
