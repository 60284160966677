@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.links {
  $color: var(--coloring);

  .card-title {
    font-weight: $semi-bold;
    color: $color;
  }

  .token-section {
    border: 1px solid #646464;
    border-radius: 24px;
    padding: 40px;
    max-width: 1200px;
    margin: auto;
    pointer-events: none;

    @include mq-max($md) {
      padding: 24px;
    }

    .title {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background-color: $main-bg;
      padding: 8px;
    }
  }
}
