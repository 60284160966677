@use "@scss/global.scss" as *;

.shadow-box {
  padding: 40px;
  border-radius: $size-3;
  border: 1px solid rgba($white-0, 0.1);
  background: rgba(#000000, 0.01);
  height: max-content;
  width: 100%;
  max-width: 520px;
  backdrop-filter: blur(10px);

  @include mq-max($md) {
    padding: 24px;
    max-width: 358px;
  }
}
