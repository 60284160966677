@use "@scss/global.scss" as *;

.carousel-wrapper {
  height: max-content;
  max-width: 100vw;
  padding-left: $base-side-padding;
  overflow-x: scroll;
  padding-top: 16px;
  padding-bottom: 16px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
  }
  scrollbar-width: none; /* Firefox */

  .carousel {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-right: $base-side-padding;

    width: max-content;
    height: max-content;
  }
}
