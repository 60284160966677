@use "@scss/global.scss" as *;

.light-paper {
  .lightpaper-btn {
    min-width: 260px;
    background: rgba($white-0, 0.05);
    border: 1px solid $white-0;
    border-radius: $roundness-100;
    height: 40px;

    transition: background 0.15s ease-in-out;

    &:hover {
      background: rgba($white-0, 0.15);
    }
  }
}
