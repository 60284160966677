@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

.col {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.grid {
  max-width: 100%;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.d-b {
  display: block;
}

.d-n {
  display: none;
}

.d-f {
  display: flex;
}

.click-insensitive {
  -webkit-tap-highlight-color: transparent;
}

.text-center {
  text-align: center;
}

.t-c {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ellipsis {
  word-wrap: break-word;
  @include ellipsis-after-lines(1);
}

.overflow-n {
  overflow: hidden;
}

.overflow-x-n {
  overflow-x: hidden;
}

.overflow-y-n {
  overflow-y: hidden;
}

.pointer {
  cursor: pointer;
}

.required-text {
  &:after {
    content: "*";
    color: var(--danger);
  }
}

.none-events {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.capitalize-each {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  @include absolute-center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.thin {
  font-weight: $thin;
}
.light {
  font-weight: $light;
}
.regular {
  font-weight: $regular;
}
.medium {
  font-weight: $medium;
}
.semi-bold {
  font-weight: $semi-bold;
}
.bold {
  font-weight: $bold;
}
.extra-bold {
  font-weight: $extra-bold;
}
.black-bold {
  font-weight: $black-bold;
}

.op-0 {
  opacity: 0;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}
.op-10 {
  opacity: 1;
}

%roundness-1 {
  border-radius: $roundness-1;
}
%roundness-2 {
  border-radius: $roundness-2;
}
%roundness-3 {
  border-radius: $roundness-3;
}
%roundness-4 {
  border-radius: $roundness-4;
}
%roundness-5 {
  border-radius: $roundness-5;
}
%roundness-6 {
  border-radius: $roundness-6;
}
%roundness-7 {
  border-radius: $roundness-7;
}
%roundness-8 {
  border-radius: $roundness-8;
}
%roundness-9 {
  border-radius: $roundness-9;
}
%roundness-10 {
  border-radius: $roundness-10;
}
%roundness-100 {
  border-radius: $roundness-100;
}

.roundness-1 {
  @extend %roundness-1;
}
.roundness-2 {
  @extend %roundness-2;
}
.roundness-3 {
  @extend %roundness-3;
}
.roundness-4 {
  @extend %roundness-4;
}
.roundness-5 {
  @extend %roundness-5;
}
.roundness-6 {
  @extend %roundness-6;
}
.roundness-7 {
  @extend %roundness-7;
}
.roundness-8 {
  @extend %roundness-8;
}
.roundness-9 {
  @extend %roundness-9;
}
.roundness-10 {
  @extend %roundness-10;
}
.roundness-100 {
  @extend %roundness-100;
}

.white-0 {
  color: $white-0;
}

.shade-1 {
  color: $shade-1;
}

.shade-2 {
  color: $shade-2;
}

.shade-3 {
  color: $shade-3;
}

.shade-4 {
  color: $shade-4;
}

.shade-5 {
  color: $shade-5;
}

.shade-6 {
  color: $shade-6;
}

.shade-7 {
  color: $shade-7;
}

.shade-8 {
  color: $shade-8;
}

.shade-9 {
  color: $shade-9;
}

.shade-10 {
  color: $shade-10;
}

.shade-11 {
  color: $shade-11;
}

.shade-12 {
  color: $shade-12;
}

.shade-13 {
  color: $shade-13;
}

.black-0 {
  color: $black-0;
}

.success {
  color: var(--success);
}

.bg-success {
  background: var(--success);
}

.danger {
  color: var(--danger);
}

.bg-danger {
  background: var(--danger);
}

.warning {
  color: var(--warning);
}

.bg-warning {
  background: var(--warning);
}

.transparent {
  color: transparent;
}

.bg-transparent {
  background: transparent;
}

.green {
  color: var(--green);
}

.blue {
  color: var(--blue);
}

.gold {
  color: var(--gold);
}

.purple {
  color: var(--purple);
}

.gradient-gold-top-down {
  @include linear-gradient-top-down(var(--gold), var(--gold-darker));
}
.gradient-gold-left-right {
  @include linear-gradient-left-right(var(--gold), var(--gold-darker));
}
.gradient-green-top-down {
  @include linear-gradient-top-down(var(--green), var(--green-darker));
}
.gradient-green-left-right {
  @include linear-gradient-left-right(var(--green), var(--green-darker));
}
.gradient-purple-top-down {
  @include linear-gradient-top-down(var(--purple), var(--purple-darker));
}
.gradient-purple-left-right {
  @include linear-gradient-left-right(var(--purple), var(--purple-darker));
}
.gradient-blue-top-down {
  @include linear-gradient-top-down(var(--blue), var(--blue-darker));
}
.gradient-blue-left-right {
  @include linear-gradient-left-right(var(--blue), var(--blue-darker));
}

.text-gradient-gold-top-down {
  @include text-gradient-top-down(var(--gold), var(--gold-darker));
}
.text-gradient-gold-left-right {
  @include text-gradient-left-right(var(--gold), var(--gold-darker));
}
.text-gradient-green-top-down {
  @include text-gradient-top-down(var(--green), var(--green-darker));
}
.text-gradient-green-left-right {
  @include text-gradient-left-right(var(--green), var(--green-darker));
}
.text-gradient-purple-top-down {
  @include text-gradient-top-down(var(--purple), var(--purple-darker));
}
.text-gradient-purple-left-right {
  @include text-gradient-left-right(var(--purple), var(--purple-darker));
}
.text-gradient-blue-top-down {
  @include text-gradient-top-down(var(--blue), var(--blue-darker));
}
.text-gradient-blue-left-right {
  @include text-gradient-left-right(var(--blue), var(--blue-darker));
}
