@use "@scss/global.scss" as *;

.footer {
  overflow: hidden;

  .ring {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 55%);
    bottom: 0px;
    z-index: -1;
  }
}
