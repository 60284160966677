@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.how-to-use {
  li {
    margin-left: 16px;
    &::marker {
      color: rgba($white-0, 0.6);
      font-size: 12px;
    }
  }

  .title {
    @include h4;

    @include mq-max($md) {
      @include h3-mobile;
    }
  }

  .rings {
    @include absolute-center;
    z-index: -1;
  }
}
