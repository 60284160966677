@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

@use "sass:color";

.clickable {
  border-radius: 10px;
  width: 100%;
  min-width: max-content;
  padding: 0px $size-8;
  position: relative;

  $color: var(--clickable-color);
  $darker-color: var(--clickable-color-darker);
  $colorKey: var(--colorKey);

  &.variant {
    &-primary {
      transition: opacity var(--transition-duration) var(--transition);
      @include linear-gradient-top-down($color, $darker-color);

      &:hover {
        opacity: 0.8;
      }
    }
    &-secondary {
      border: 2px solid $darker-color;
      background: rgba($black-0, 0.2);

      transition: background var(--transition-duration) var(--transition);

      &.size-small {
        border-width: 1px;
      }

      &:hover {
        background: $colorKey;
      }
    }
  }

  &.size {
    &-big {
      height: 90px;
    }
    &-medium {
      height: 60px;
    }
    &-small {
      height: 48px;
      padding: 0px $size-3;
      border-radius: 10px;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
