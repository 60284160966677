@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.games {
  position: relative;

  .title {
    @include h2;

    @include mq-max($md) {
      @include h3-mobile;
    }
  }

  .games-desktop {
    max-width: 1200px;
    max-height: 2500px;
    overflow-y: clip;
    // overflow: hidden;

    @include mq-min($xs) {
      display: none;
    }
    @include mq-min($sm-l) {
      display: flex;
    }
    .rings {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
    }

    .bottom-gradient {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      background: linear-gradient(0deg, #06040f 0%, rgba(0, 0, 0, 0) 100%);
      height: 730px;
    }

    .more-games {
      position: absolute;
      left: 50%;
      bottom: 104px;
      transform: translateX(-50%);
    }
  }

  .games-mobile {
    @include mq-min($xs) {
      display: block;
    }
    @include mq-min($sm-l) {
      display: none;
    }
  }
  .ring {
    @include absolute-center();
    transform: translate(-50%, -24%);
    z-index: -1;
  }

  .how-to-play-link {
    border-radius: $roundness-100;
    border: 1px solid $white-0;
    padding: $size-1 $size-2;
    background: rgba($white-0, 0.05);
  }
}

a.disabled {
  pointer-events: none;
  .description {
    pointer-events: stroke;
    cursor: default;
  }
}
