@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.affiliation {
  background-image: url("../../../../public/images/affiliation/bg.avif");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 579px;

  @include mq-max($lg) {
    background-image: none;
  }

  .title {
    @include h3;

    @include mq-max($md) {
      @include h4-mobile;
    }
  }

  .bg-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25%;
    z-index: -1;
  }
}
