@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.game-card {
  position: relative;
  border-radius: $size-3;
  border: 2px solid rgba($white-0, 0.14);
  width: 336px;
  max-width: 100%;
  overflow: hidden;

  .img-wrapper {
    height: 332px;
    width: 100%;
    overflow: hidden;

    .img {
      @include relative-center();
      width: 100%;
    }

    &:hover {
      .gif {
        opacity: 1;
      }
    }
  }

  .body {
    width: 100%;
    padding: 16px;
    background: rgba($white-0, 0.05);
    backdrop-filter: blur(12px);

    .title {
      @include h4-mobile;
    }
    .description {
      @include p3-b3-mobile;
      @include ellipsis-after-lines(2);
    }
    .card-button {
    }

    &.disabled {
      .title,
      .description,
      .card-button {
        opacity: 0.7;
      }
      background: rgba($white-0, 0.02);
    }
  }

  &.mobile {
    width: 70vw;
    max-width: 300px;
    min-width: 260px;

    .img-wrapper {
      height: 260px;
      .img {
      }
    }
    .body {
      padding: 16px;
      padding-top: 16px;
      .title {
      }
      .description {
      }
      .card-button {
      }
    }
  }

  .gif {
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
    @include absolute-center;
    top: 0px;
    transform: translate(-50%, 0px);

    opacity: 0;
  }
}
