@use "@scss/consistency.scss" as *;
@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  font-size-adjust: ex-width from-font;
}

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

html {
  position: relative;
  scroll-behavior: smooth;
  color: $white-0;
  background: $main-bg;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
  overflow: hidden;
}

section {
  height: max-content;
  width: 100%;
}

html {
  min-height: 100vh;
  height: max-content;
}

body,
#root {
  height: inherit;
}

a {
  color: unset;
  text-decoration: none;
  @include flex-center();
  min-height: 40px;
  min-width: 40px;

  > * {
    height: 100%;
  }
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  border: none;
}

button,
a {
  @include user-select(none);
}

img,
a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
