@use "@scss/variables.scss" as *;

$navbar-height-desktop: 80px;
$navbar-height-mobile: 60px;

$footer-height-deksktop: 40px;
$footer-height-mobile: 30px;

$base-side-padding: $size-2;

$max-app-width: 1594px;

$main-bg: #06040f;
