@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.link-like {
  $color: var(--link-color);

  border: 0.5px solid $color;
  background: rgba($white-0, 0.05);
  border-radius: $roundness-100;
  width: max-content;
  transition: background var(--transition) var(--transition-duration);

  p {
    color: $color;
  }

  &.size {
    &-medium {
      height: 40px;
      padding: 0px $size-3;
      @include p7;
    }
    &-small {
      height: 32px;
      padding: 0px $size-2;
      @include p8;
    }
  }

  &:hover {
    background: rgba($white-0, 0.2);
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.4;
  }
}
