$white-0: #ffffff;
$shade-1: #f2f2f2;
$shade-2: #e6e6e6;
$shade-3: #d9d9d9;
$shade-4: #cccccc;
$shade-5: #b3b3b3;
$shade-6: #999999;
$shade-7: #808080;
$shade-8: #666666;
$shade-9: #4d4d4d;
$shade-10: #333333;
$shade-11: #262626;
$shade-12: #1a1a1a;
$shade-13: #0d0d0d;
$black-0: #000000;

$xs: 0px;
$sm: 360px;
$sm-l: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$roundness-1: 2px;
$roundness-2: 4px;
$roundness-3: 6px;
$roundness-4: 8px;
$roundness-5: 10px;
$roundness-6: 12px;
$roundness-7: 16px;
$roundness-8: 20px;
$roundness-9: 24px;
$roundness-10: 32px;
$roundness-100: 100vw;

$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;
$size-11: $size-dx * 11;
$size-12: $size-dx * 12;
$size-13: $size-dx * 13;
$size-14: $size-dx * 14;
$size-15: $size-dx * 15;
$size-16: $size-dx * 16;
$size-17: $size-dx * 17;
$size-18: $size-dx * 18;
$size-19: $size-dx * 19;
$size-20: $size-dx * 20;

$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;

$fs-d1: 96px;
$fs-1: 82px;
$fs-1-5: 71px;
$fs-2: 61px;
$fs-3: 52px;
$fs-4: 45px;
$fs-5: 39px;
$fs-6: 34px;
$fs-7: 29px;
$fs-8: 25px;
$fs-9: 22px;
$fs-10: 19px;
$fs-11: 16px;
$fs-12: 14px;
$fs-13: 12px;

$ls-1: -0.06em;
$ls-2: -0.05em;
$ls-3: -0.04em;
$ls-4: -0.02em;
$ls-5: -0.01em;
$ls-6: 1;
$ls-7: 0.02em;
$ls-8: 0.03em;
$ls-9: 0.2em;

$lh-84: 84%;
$lh-1: 88%;
$lh-2: 90%;
$lh-3: 100%;
$lh-4: 120%;
$lh-5: 140%;
$lh-5-5: 145%;
$lh-6: 150%;
$lh-a: auto;
