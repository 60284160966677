@use "@scss/functions.scss" as *;

:root {
  --green: #19fb9b;
  --green10: #19fb9b10;
  --green-darker: #0a766f;
  --blue: #1fcff1;
  --blue10: #1fcff110;
  --blue-darker: #1e3e95;
  --purple: #f087ff;
  --purple10: #f087ff10;
  --purple-darker: #511d90;
  --gold: #ebe275;
  --gold10: #ebe27510;
  --gold-darker: #8a8437;
  --pink: #e80767;
  --pink10: #e8076710;
  --pink-darker: #e80767;
  --metamask: #f5841f;
  --arbiscan: #12aaff;

  --success: #63af00;
  --danger: #ca3000;
  --warning: #e9c300;
  --info: #016fd0;

  --text-gradient-gold-top-down: text-gradient-top-down(var(--gold), var(--gold-darker));
  --text-gradient-gold-left-right: text-linear-gradient(var(--gold), var(--gold-darker));

  --text-gradient-green-top-down: text-gradient-top-down(var(--green), var(--green-darker));
  --text-gradient-green-left-right: text-linear-gradient(var(--green), var(--green-darker));

  --text-gradient-purple-top-down: text-gradient-top-down(var(--purple), var(--purple-darker));
  --text-gradient-purple-left-right: text-linear-gradient(var(--purple), var(--purple-darker));

  --text-gradient-blue-top-down: text-gradient-top-down(var(--blue), var(--blue-darker));
  --text-gradient-blue-left-right: text-linear-gradient(var(--blue), var(--blue-darker));

  --transition: cubic-bezier(0.76, 0, 0.25, 0.99);
  --transition-duration: 0.3s;
}
