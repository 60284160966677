@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.hero {
  .logo-holder {
    position: relative;
    .logo {
      margin-bottom: 20px;
    }
    .rings {
      @include absolute-center;
      z-index: -1;
    }
  }
  .title-holder {
    position: relative;
    .rings {
      @include mq-min($xs) {
        display: none;
      }
      @include mq-min($md) {
        display: block;
      }
      @include absolute-center;
      z-index: -1;
    }
  }

  .title {
    @include h2;

    @include mq-max($lg) {
      @include h3-mobile;
    }
  }

  .video {
    max-width: 100%;
    border-radius: 16px;
  }
}
