@use "@scss/typography-mixins.scss" as *;
@use "@scss/mixins.scss" as *;
@use "@scss/variables.scss" as *;

.d1 {
  @include d1;

  @include mq-max($md) {
    @include d1-mobile;
  }
}
h1,
.h1 {
  @include h1;

  @include mq-max($md) {
    @include h1-mobile;
  }
}
h2,
.h2 {
  @include h2;

  @include mq-max($md) {
    @include h2-mobile;
  }
}
h3,
.h3 {
  @include h3;

  @include mq-max($md) {
    @include h3-mobile;
  }
}

.h3-mobile {
  @include h3-mobile;
}

h4,
.h4 {
  @include h4;

  @include mq-max($md) {
    @include h4-mobile;
  }
}

.h4-mobile {
  @include h4-mobile;
}

h5,
.h5 {
  @include h5;

  @include mq-max($md) {
    @include h5-mobile;
  }
}
h6,
.h6 {
  @include h6;

  @include mq-max($md) {
    @include h6-mobile;
  }
}

.p1,
.eyebrow {
  @include p1;

  @include mq-max($md) {
    @include p1-mobile;
  }
}
.p2,
.b1 {
  @include p2;

  @include mq-max($md) {
    @include p2-mobile;
  }
}
.p3,
.b2 {
  @include p3;

  @include mq-max($md) {
    @include p3-mobile;
  }
}

.b3 {
  @include p3-b3;

  @include mq-max($md) {
    @include p3-b3-mobile;
  }
}

.b4 {
  @include p3-b4;
}

.caption-medium {
  text-transform: uppercase;
  @include caption-medium;

  @include mq-max($md) {
    @include caption-small;
  }
}
.p5,
.butt-1 {
  @include p5;
}
.p6,
.butt-2 {
  @include p6-mobile;
}
.p7,
.butt-3 {
  @include p7;
}
