@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

@keyframes opacity-transition {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes backdrop-filter-transition {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(5px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}

@keyframes smooth-appear {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulsing-scale {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(1.05);
  }

  66% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.animation-pulse {
  animation: pulsing-scale 1s infinite;
  animation-timing-function: linear;
}

@keyframes bg_blur {
  0% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}
