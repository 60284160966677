@use "@scss/global.scss" as *;
@use "@scss/typography-mixins.scss" as *;

.profits {
  .title {
    @include h4;

    @include mq-max($md) {
      @include h3-mobile;
    }
  }
  .img {
    position: relative;
    top: -385px;
    margin-bottom: -660px;
    z-index: -1;
  }

  .img-mobile {
    max-width: 100%;
  }

  .gaymer {
    position: absolute;
    left: 45%;
    top: 0px;
    z-index: -1;
  }
}
